/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

body {
    --graded: #ff7c00;
    --gradec: #ffc000;
    --gradeb: #c5e0b3;
    --gradea: #92d050;
    --grade: #82f802;
    --critical: #B33D36;
    --high: #EB973E;
    --medium: #FCE94E;
    --low: #6fb8f9;
    --info: #97d7ff;
    --not-applicable: #6d6d6d;
    --compliant: #61AD52;
    --tsuccess: #0dc97f;
    --terror: #f95668;
    --tinfo: #4285f4;
    --twarning: #f8b15e;
}
.bradius-23{ border-radius: 23px; }
.stad-color { color: #97A7C3 !important; }
.toast-success {background-color: var(--tsuccess);}
.toast-error {background-color: var(--terror);}
.toast-info {background-color: var(--tinfo);}
.toast-warning {background-color: var(--twarning);}

.t-gray{color: var(--gray);}
.t-c{color: var(--critical);}
.t-h{color: var(--high);}
.t-m{color: var(--medium);}
.t-l{color: var(--low);}
.t-i{color: var(--info);}
.t-d{color: var(--not-applicable);}
.t-g{color: var(--compliant);}

.gradef,.gradef:hover{color:#fff!important;background-color:var(--gradef)!important}
.graded,.graded:hover{color: #444444 !important;background-color: var(--graded) !important}
.gradec,.gradec:hover{color: #444444 !important;background-color:var(--gradec)!important}
.gradeb,.gradeb:hover{color: #444444 !important;background-color:var(--gradeb)!important}
.gradea,.gradea:hover{color: #444444 !important;background-color:var(--gradea)!important}
.grade,.grade:hover{color: #444444 !important;background-color:var(--gradea)!important}
.spacer { flex: 1 1 auto; }
.mini-form-field {transform: translateY(0em) scale(0.8) !important;}
.imageWidth { width: 17px !important;  margin-left: 9px; }
.imageWidthDate { width: 25px !important;  margin-left: 5px; }
.vendor-logo { width: 18px; }
.whitespace-pre-wrap { white-space: pre-wrap !important; }
.detailscount-box {
    border-radius: 14px;
    box-shadow: inset 2px 2px 3px #D1D9E6;
    .count-down {
        font-size: x-large;
        text-align: left;
        /*font: normal normal 900 25px/34px Segoe UI !important;*/
        letter-spacing: 0px;
        opacity: 1;
    }
}
.countdown-box {
    box-shadow: inset 2px 2px 3px #D1D9E6;
    border-radius: 23px;
    .count-title {
        opacity: 1;
    }
    .count-down {
        font-size: x-large;
        text-align: left;
        /*font: normal normal 900 25px/34px Segoe UI !important;*/
        letter-spacing: 0px;
        opacity: 1;
    }
}
.stdbox {
    box-shadow: 0px 5px 5px -3px rgb(255 255 255 / 25%), 0px 8px 10px 1px rgb(207 216 223), 0px 3px 14px 2px rgb(255 255 255 / 96%);
    background-color: #ecf0f3 !important;
    border-color: #ecf0f3 !important;
}
.tmt10 {
    margin-top: -10px !important;
}
.-mt55 {
    margin-top: -55px !important;
}
.diagn {
    background-color: transparent;
    background-image: -webkit-linear-gradient(281deg, #013a6b00 50%, #f1f5f9 50%);
    width: -webkit-fill-available !important;
    height: 113px;
    margin-top: -48px;
}
.incident-box {
    /*box-shadow: inset 1px 1px 5px 4px #e2e4e6;*/
    border-radius: 23px;
    background-color: #fff0f0e8 !important;
}
.default-bg {
    background-color: #ECF0F3 !important
}

.f18 {
    font-size: 1.8rem !important;
}
.smicon {
    max-width: 12px;
    min-height: 12px;
    font-size: 12px;
    line-height: 12px;
}
.green {
    background-color: #ecf0f3 !important;
    .count-title {
        color: #97A7C3 !important;
    }
    .count-down {
        color: #149168 !important;
    }
}
.service-table tr:nth-child(odd) {
    background-color: #E1E8F3;
    /* padding: 6px; */
}
.red {
    background-color: #FF0000 !important;
    .count-title {
        color: white !important;
    }
    .count-down {
        color: white !important;
    }
}
.btn-disabled {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity);
}
.rbr{
   border-right: 1px solid #193566;
}
.btn-light {
    --bs-btn-color: #000;
    --bs-btn-bg: #f8f9fa;
    --bs-btn-border-color: #f8f9fa;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #d3d4d5;
    --bs-btn-hover-border-color: #c6c7c8;
    --bs-btn-focus-shadow-rgb: 211,212,213;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #c6c7c8;
    --bs-btn-active-border-color: #babbbc;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #000;
    --bs-btn-disabled-bg: #f8f9fa;
    --bs-btn-disabled-border-color: #f8f9fa;
}
.detailCount {
    width: calc(11rem + 1.6vw);
}
.overflow-custom-auto{
    overflow: hidden;
    &:hover {
        overflow: auto;
    }
}
.cover-img {
    background: url(/assets/lsc/ioml.jpeg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: 100% 100%;
}
